import { request } from "utils/http/Request";
import axios from "axios";
import { PRIVATE_FORMS } from "constants/paths";
import { IFile, IFormData } from "models/Survey";

export const getSurveyData = async (id: string) => {
  return await request.get(`${PRIVATE_FORMS}/${id}`);
};

export const putSurveyData = async (id: string, form_data: IFormData) => {
  return await request.put(`${PRIVATE_FORMS}/${id}`, {
    referral_data: form_data.questions,
    progress: form_data.progress,
  });
};

export const putSurveyComplete = async (id: string) => {
  return await request.put(`${PRIVATE_FORMS}/${id}`, { submitted: true });
};

export const postFile = async (file: IFile, id: string) => {
  const signedUrl = await request.post(
    `${PRIVATE_FORMS}/${id}/attachments/${file.path}`
  );
  return await axios.put(signedUrl.PresignedUrl, file, {
    headers: {
      contentType: file.type,
    },
  });
};
