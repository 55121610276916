import React, { useState } from "react";
import TextInput, {
  ITextInput,
} from "components/shared/ui/text-input/TextField";
import EyeVisibleIcon from "components/shared/ui/icons/IconEyeVisible";
import IconEyeHidden from "components/shared/ui/icons/IconEyeHidden";
import styles from "components/shared/ui/text-input/TextField.module.scss";

interface IPasswordWrap extends ITextInput {
  component: typeof TextInput;
}

const PasswordTextWrapper = React.forwardRef(
  ({ component: Component, ...props }: IPasswordWrap, ref: React.Ref<any>) => {
    const [displayPassword, toggleDisplay] = useState(false);
    return (
      <div className={styles.passwordWrapper}>
        <Component
          {...props}
          type={displayPassword ? "text" : "password"}
          ref={ref}
        />
        <div
          className={styles.toggle}
          onClick={() => toggleDisplay(!displayPassword)}
        >
          {displayPassword ? <EyeVisibleIcon /> : <IconEyeHidden />}{" "}
        </div>
      </div>
    );
  }
);

export const PasswordTextInput = React.forwardRef(
  (props: ITextInput, ref: React.Ref<any>) => (
    <PasswordTextWrapper component={TextInput} {...props} ref={ref} />
  )
);

export default PasswordTextInput;
