import React from "react";
import Meta from "components/shared/Meta";
import SurveyLayout from "components/shared/layouts/SurveyLayout";
import Loader from "components/shared/ui/loader/Loader";

const Content = React.lazy(() => import("components/views/Landing/LandingContent"));

const ViewLanding = () => {
  return (
    <SurveyLayout>
      <Meta title={"Online Referral Form"} />
        <React.Suspense fallback={<Loader />}>
          <Content />
        </React.Suspense>
    </SurveyLayout>
  );
};

export default ViewLanding;
