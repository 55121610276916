import React from "react";
import Button from "components/shared/ui/button/Button";
import { logFeedback } from "utils/errorUtils";
import { SUPPORT_EMAIL } from "constants/site";
import SurveyLayout from "components/shared/layouts/SurveyLayout";
import styles from "./ErrorPage.module.scss";
import ROUTES from "constants/routes";

interface Props {
  error: any;
  componentStack: () => void;
  resetError: () => void;
  eventId: string;
}

const ErrorPage = ({ resetError, eventId }: Props) => {
  const onClick = () => {
    resetError();
    window.location.href = ROUTES.HOME;
  };

  return (
    <SurveyLayout>
      <h1 className={styles.title}>
        It looks like you've encountered an error!
      </h1>
      <div className={styles.description}>
        We've automatically logged the issue
        {logFeedback ? (
          <>
            <span>, but please feel free to provide more detail:</span>
            <br />
            <br />
            <Button onClick={() => logFeedback(eventId)} variant="outlined">
              Provide More Detail
            </Button>
            <br />
            <br />
            <span>I</span>
          </>
        ) : (
          <span>, but i</span>
        )}
        f you keep having trouble please contact: <br />
        <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        <br />
        <br />
        <br />
        Otherwise, the link below will take you back to the referral page
      </div>
      <div>
        <Button onClick={onClick} theme="primary">
          Back to Home
        </Button>
      </div>
    </SurveyLayout>
  );
};

export default ErrorPage;
