import { appGetState, appNextState, appUpdateState } from "store";
import { initialState } from "store/state";
import { Auth } from "aws-amplify";
import { setCognitoSession } from "api/localStorage";

const comment = (m: string) => `AuthService::${m}`;

class AuthService {
  private appGetState = appGetState;
  private appNextState = appNextState;
  private appUpdateState = appUpdateState;

  /**
   */
  public async checkAuth() {
    try {
      await this.checkSession();
      const user = await this.getUserInfo();
      this.appUpdateState((s) => (s.auth.loggedIn = true), comment("loggedIn"));
      return user;
    } catch (e) {
      this.appUpdateState(
        (s) => (s.auth.loggedIn = false),
        comment("loggedOut")
      );
      return false;
    }
  }

  public async checkSession() {
    return await Auth.currentSession();
  }

  public async setSession(token: any) {
    await setCognitoSession(token);
    return await this.checkAuth();

  }

  /**
   * @return false or continue function
   */
  public async login(
    email: string,
    password: string
  ): Promise<boolean | (() => void)> {
    try {
      const user = await Auth.signIn(email, password);
      this.appUpdateState((s) => (s.auth.user = user), comment("user"));
      if (user && !user.challengeName) {
        this.appUpdateState(
          (s) => (s.auth.error = null),
          comment("clearError")
        );
        return true;
      }
      return false;
    } catch (e) {
      this.appUpdateState((s) => (s.auth.error = e), comment("authError"));
    }
    return false;
  }

  public async getUserInfo(): Promise<boolean | Record<string, any>> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.appUpdateState((s) => (s.auth.user = user), comment("userInfo"));
      return user;
    } catch (e) {
      this.appUpdateState((s) => (s.auth.error = e), comment("userInfoError"));
    }
    return false;
  }

  /**
   */
  public async logout(): Promise<void> {
    this.appNextState({ ...initialState }, comment("logout"));
    await Auth.signOut();
  }
}

const authService = new AuthService();
export { authService };
