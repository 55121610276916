import React from "react";
import ErrorPage from "components/shared/ui/error/ErrorPage";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

export interface Props {
  fallback?: React.ReactNode;
  children: React.ReactNode;
}

const ErrorBoundary = ({ fallback = ErrorPage, children, ...rest }: Props) => {
  console.log(rest);
  return (
    <SentryErrorBoundary fallback={fallback}>{children}</SentryErrorBoundary>
  );
};

export const withComponentErrorBoundary = (
  ComponentThatMayError: React.ComponentType<any>,
  FallbackComponent: React.ReactNode = ErrorPage
) => (props: any) => (
  <ErrorBoundary fallback={FallbackComponent}>
    <ComponentThatMayError {...props} />
  </ErrorBoundary>
);

export const withErrorBoundary = (
  ComponentThatMayError: React.ComponentType<any>,
  FallbackComponent: React.ReactNode
) => (props: any) => (
  <ErrorBoundary fallback={FallbackComponent}>
    <ComponentThatMayError {...props} />
  </ErrorBoundary>
);

export { ErrorBoundary };
