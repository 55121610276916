import React from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import TaikuraFooter from "components/shared/ui/footer/TaikuraFooter";
import Logo from "components/shared/images/Logo";
import styles from "./SurveyLayout.module.scss";

const SurveyLayout = (props: {
  className?: string;
  spacing?: "none" | "small" | "normal" | "large" | "extra";
  children: any;
}) => {
  return (
    <motion.div
      className={cx(props.className, styles.container)}
      {...containerAnimations}
      // variants={variants}
    >
      <motion.main
        className={cx(
          styles.content,
          props.spacing && styles[`spacing-${props.spacing}`]
        )}
      >
        <Logo className={styles.logo} />
        {props.children}
      </motion.main>
      <TaikuraFooter />
    </motion.div>
  );
};

const transition = { duration: 0.6, ease: "easeOut", delay: 0.3 };
const containerAnimations = {
  initial: { x: 0, y: 0, opacity: 0 },
  animate: { x: 0, y: 0, opacity: 1, transition },
  exit: {
    // x: 0,
    y: 60,
    opacity: 0,
    transition: { ease: "circIn", duration: 0.35 },
  },
};

export default SurveyLayout;
