export const HOME = "/";

export const SURVEY_ROOT = "/referral/:userId/:formId";
export const SURVEY = SURVEY_ROOT + "#:page";
export const UPLOAD = "/referral-documents/:userId/:formId";
export const REFERRAL_SUCCESS = "/referral-submitted";

export const ERROR = "/referral-error";
export const TESTING = "/x991230x-testing-z1081731q";

export const getSurveyPage = (
  formId: string,
  userId: string,
  pageID: string
) => {
  return `${SURVEY_ROOT.replace(":userId", userId).replace(
    ":formId",
    formId
  )}#${pageID}`;
};

export const getUploadPage = (formId: string, userId: string) => {
  return `${UPLOAD.replace(":userId", userId).replace(":formId", formId)}`;
};

const ROUTES = {
  HOME,
  SURVEY_ROOT,
  SURVEY,
  REFERRAL_SUCCESS,
  UPLOAD,
  ERROR,
};

export default ROUTES;
