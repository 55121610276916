import React from "react";
import styles from "./Loader.module.scss";

const Loader = () => (
  <div className={styles.root}>
    <div className={styles.dot} />
    <div className={styles.dot2} />
  </div>
);

export default Loader;
