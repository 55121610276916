const primary = "#2b2572";
const TaikuraAmplifyTheme = {
  container: {
    // fontFamily: "'Montserrat', sans-serif",
    // color: "#595959",
  },
  formContainer: {
    fontFamily: "'Montserrat', sans-serif",
    color: "#595959",
    margin: "0 auto",
    height: "100vh",
    backgroundColor: "#fff",
    maxWidth: "800px",
  },
  sectionHeader: {
    color: primary,
    fontWeight: 700,
    fontSize: "24px",
    minWidth: 400,
  },
  button: {
    fontFamily: "'Montserrat', sans-serif",
    borderRadius: "30px",
    backgroundColor: primary,
    color: "#fff",
    fontWeight: 700,
    padding: "12px 22px",
    marginRight: "16px",
  },
  sectionBody: {
    padding: "24px 0",
  },
  input: {
    fontFamily: "'Montserrat', sans-serif",
    borderRadius: "60px",
    padding: "9px 12px",
    width: "100%",
    outline: 0,
    "input:focus": {
      outline: "none",
      borderColor: primary,
    },
  },
  a: { color: "#4fcfdf" },
  formSection: {
    margin: "5% 0 0 0",
    padding: "80px",
    // minWidth:"600px"
  },
  toast: {
    display: "none",
    color: "#fff",
    backgroundColor: primary,
    boxShadow: "0 0 5px 0 rgba(0,0,0,0.7)",
  },
};

export default TaikuraAmplifyTheme;
