import * as React from "react";
import { SITE_LONG_NAME } from "constants/site";

interface TMeta {
  name?: string;
  title?: string;
}

const Meta = React.memo(({ name, title }: TMeta) => {
  React.useEffect(() => {
    document.title = `${title ? `${title} | ` : ""}${name || SITE_LONG_NAME}`;
  }, [name, title]);

  return null;
});

export default Meta;
