import { create as createUser } from "models/User";
import { TBreadcrumb } from "services/LayoutService";
import { getCachedSurvey, getCachedFormID } from "api/localStorage";
import { IFormData } from "models/Survey";
import { CURRENT_SURVEY_VERSION } from "config/SurveyConfig";
import { IToastStateElement } from "components/shared/ui/toast/Toast";

export const initialState = {
  auth: {
    loggedIn: false,
    user: null as any,
    error: null as AuthError,
  },
  currentUser: createUser({}),
  layout: {
    isExpanded: false,
    isMobileOpened: false,
    isHeaderLarge: false,
    breadcrumbs: [] as TBreadcrumb[],
    dialog: {
      open: false,
      title: "",
      content: "",
      cancel: true,
      actionLabel: "",
      action: () => {},
    },
    snackbar: {
      open: false,
      variant: "success",
      message: "Success",
    },
    toasts: [] as IToastStateElement[],
  },
  survey: {
    fetched: false,
    fetching: false,
    altWording: false,
    id: getCachedFormID() as string | undefined,
    client_first_name: "" as string | undefined,
    client_surname: "" as string | undefined,
    referral_version: CURRENT_SURVEY_VERSION as number,
    form_data: getCachedSurvey().form_data as IFormData,
  },
};

export type IAppState = typeof initialState;
export interface IState {
  app: IAppState;
  internal: TObjectAny;
}
