const initialData = {
  id: "",
  email: "",
  firstName: "",
  lastName: "",
  role: 0,
  avatar: "",
  created: 0,
  updated: 0,
  activated: false,
  suspended: false,
};

export type TModel = typeof initialData;

export const create = (data?: Partial<TModel>): TModel => ({
  ...initialData,
  ...data,
});

export const map = (data: TObjectAny): TModel => ({
  id: data.username || initialData.id,
  email: data.email || initialData.email,
  firstName: data.first_name || initialData.firstName,
  lastName: data.last_name || initialData.lastName,
  role: parseInt(data.role) || 0,
  avatar: `${data.avatar}`,
  created: parseInt(data.created) || initialData.created,
  updated: parseInt(data.updated) || initialData.updated,
  activated: !!data.activated,
  suspended: !!data.suspended,
});

export const toServerObject = (item: TModel) => ({
  username: item.id,
  email: item.email,
  first_name: item.firstName,
  last_name: item.lastName,
  role: item.role,
  avatar: item.avatar,
  activated: item.activated,
  suspended: item.suspended,
});

export const roleOptions = [
  { value: 20, label: "User" },
  { value: 30, label: "Manager" },
  { value: 40, label: "Site Admin" },
  { value: 99, label: "Super Admin" },
];
