import React, {useEffect} from "react";
import Meta from "components/shared/Meta";
import SurveyLayout from "components/shared/layouts/SurveyLayout";
import * as ROUTES from "constants/routes";
import Button from "components/shared/ui/button/Button";
import {SUPPORT_EMAIL} from "constants/site";
import {authService} from "services/AuthService";
import {surveyService} from "services/SurveyService";

const ViewErrorLoadingSurvey = () => {
  const reset = async ()=>{
    await authService.logout();
    surveyService.resetSurvey();
  }
  useEffect(() => {
    reset()
  }, []);
  return (
    <SurveyLayout>
      <Meta title={"Referral Error"} />
      <br />
      <h1>Error Loading Referral</h1>
      <p>
        Sorry, there was an error loading your referral, or you may be trying to edit two referrals at once.
        <br />
        Please try reloading the referral from the link and access code emailed to you.
        <br /><br />
        If you keep having trouble, please contact{" "}
        <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        <br />
        <br />
      </p>
      <Button to={ROUTES.HOME} theme="primary">
        Back to Home
      </Button>
    </SurveyLayout>
  );
}

export default ViewErrorLoadingSurvey;
