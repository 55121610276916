import {
  ISurveyVersion,
  SurveyPageConfig,
} from "models/Survey";
import version1 from "./versions/version-1.json";
import { VERSION_1_VALIDATIONS } from "config/versions/validation";
import { formatVersion } from "utils/surveyUtils";

export const CURRENT_SURVEY_VERSION = 1;

export const surveyVersions = {
  "1": formatVersion(version1 as SurveyPageConfig[], VERSION_1_VALIDATIONS),
} as { [key: string]: ISurveyVersion };
