import * as React from "react";
import logo from "./taikura.jpg";

interface Props {
  className?: string;
}

const Logo = React.memo(({ className }: Props) => (
  <img
    src={logo}
    className={className}
    title={"Taikura Trust"}
    alt={"Taikura Trust logo"}
  />
));

export default Logo;
