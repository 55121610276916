import React from "react";
import Meta from "components/shared/Meta";
import SurveyLayout from "components/shared/layouts/SurveyLayout";
import { useSelector } from "react-redux";
import { IState } from "store";
import { History } from "history";
import { surveyService } from "services/SurveyService";
import { authService } from "services/AuthService";
import Loader from "components/shared/ui/loader/Loader";
import ROUTES from "constants/routes";

const ReferralFormContent = React.lazy(() =>
  import("./ReferralForm/ReferralFormContent")
);
const UploadContent = React.lazy(() => import("./Upload/UploadContent"));

interface Props {
  match: { path: string; params: { formId: string; userId: string } };
  location: { hash: string };
  history: History;
}
const Loading = () => (
  <div style={{ position: "relative", height: 200 }}>
    <Loader />
  </div>
);

const ViewReferralForm = (props: Props) => {
  const { fetched, fetching } = useSelector((s: IState) => s.app.survey);
  const {
    match: {
      path,
      params: { formId },
    },
    history,
  } = props;
  const upload = path === ROUTES.UPLOAD;

  const fetchSurvey = async ()=>{
    try {
      await surveyService.fetchSurvey(formId);
    } catch (e) {
      //403 usually means user is logged in with another survey credentials
      if (e.message === "Request failed with status code 403") {
        await authService.logout();
        surveyService.resetSurvey();
      } else {
        history.push(ROUTES.ERROR);
      }
    }
  }
  if (!fetched && !fetching) {
    fetchSurvey();
  }

  return (
    <SurveyLayout>
      <Meta title={upload ? "Supporting Documents" : "Self Referral Form"} />
      {upload ? (
        <React.Suspense fallback={<Loader />}>
          <UploadContent {...props} />
        </React.Suspense>
      ) : (
        <React.Suspense fallback={<Loading />}>
          <ReferralFormContent {...props} />
        </React.Suspense>
      )}
    </SurveyLayout>
  );
};

export default ViewReferralForm;
