import axios, { AxiosInstance } from "axios";
import { RequestCancel } from "./RequestCancel";
import qs from "qs";
import { Auth } from "aws-amplify";

class Request {
  private readonly client: AxiosInstance;
  private headers: TObjectAny = {};

  public constructor() {
    this.client = axios.create({
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    });

    // Add Cognito id token to all requests
    // Amplify will handle the refresh if needed
    this.client.interceptors.request.use((config) => {
      //If Authorization headers already present then skip
      if (!config.headers.Authorization) {
        return Auth.currentSession()
          .then((session) => {
            const idToken = session.getIdToken();
            config.headers.Authorization = `Bearer ${idToken.getJwtToken()}`;
            return Promise.resolve(config);
          })
          .catch(() => {
            return Promise.resolve(config);
          });
      }
      return Promise.resolve(config);
    });

    this.setHeaders({
      "Content-Type": "application/json; charset=UTF-8",
    });
  }

  public async get(
    url: string,
    payload: Object = {},
    headers?: Object,
    cancelObject?: RequestCancel
  ) {
    return this.do("GET", url, payload, headers, cancelObject);
  }

  public async post(
    url: string,
    payload: Object = {},
    headers?: Object,
    cancelObject?: RequestCancel
  ) {
    return this.do("POST", url, payload, headers, cancelObject);
  }

  public async put(
    url: string,
    payload: Object = {},
    headers?: Object,
    cancelObject?: RequestCancel
  ) {
    return this.do("PUT", url, payload, headers, cancelObject);
  }

  public async delete(
    url: string,
    payload: Object = {},
    headers?: Object,
    cancelObject?: RequestCancel
  ) {
    return this.do("DELETE", url, payload, headers, cancelObject);
  }

  public setHeaders(headers: TObjectAny) {
    this.headers = headers;
  }

  protected async do(
    method: "GET" | "POST" | "PUT" | "DELETE",
    url: string,
    payload: Object,
    headers?: Object,
    cancelObject?: RequestCancel
  ): Promise<TObjectAny> {
    const options = {
      method,
      url,
      headers: { ...this.headers, ...headers },
      params: {},
      data: {},
      cancelToken: new axios.CancelToken((c) => {
        if (cancelObject) {
          cancelObject.setCancelFunction(c);
        }
      }),
    };

    method === "GET" ? (options.params = payload) : (options.data = payload);

    try {
      const { data } = await this.client(options);
      return data || {};
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.messages
          ? error.response.data.messages.join(", ")
          : null;
      window.logger.error(message || error);
      error.serverMessage = message;
      throw error;
    }
  }
}

const request = new Request();

export { request };
