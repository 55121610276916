import * as Yup from "yup";
import { isNHI } from "utils/validationUtils";


export const addressValidation = Yup.object().shape({
  postcode: Yup.string().when("custom_address", {
    is: false,
    then: Yup.string().required("Please enter a postcode"),
  }),
  custom_postcode: Yup.string().when("custom_address", {
    is: true,
    then: Yup.string().required("Please enter a postcode"),
  }),
  custom_street: Yup.string().when("custom_address", {
    is: true,
    then: Yup.string().required("Please enter a street address"),
  }),
});

export const VERSION_1_VALIDATIONS = {
  referrer: Yup.string().required("Please indicate who is making the referral"),
  "referrer-health-org": Yup.string().when("referrer", {
    is: (v) => v === "professional",
    then: Yup.string().required("Please enter your organisation"),
  }),
  "referrer-name": Yup.string().when("referrer", {
    is: (v) => ["family", "professional", "other"].includes(v),
    then: Yup.string().required("Please enter your first and last name"),
  }),
  "referrer-email": Yup.string().when("referrer", {
    is: (v) => ["family", "professional", "other"].includes(v),
    then: Yup.string()
      .required("Please enter your email address")
      .email("Please enter a valid email address"),
  }),
  "consent-disclose-info": Yup.boolean().oneOf(
    [true],
    "You must give consent before you can continue"
  ),
  "consent-request-info": Yup.boolean().oneOf(
    [true],
    "You must give permission before you can continue"
  ),

  //Client Details
  "client-dob": Yup.string()
    .matches(
      /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
      "Please enter a valid Date of Birth"
    )
    .length(10, "Please enter a valid Date of Birth"),
  "client-email": Yup.string().email("Please enter a valid email address"),
  "client-address": addressValidation,
  "client-ethnicity": Yup.string().required(
    "Please selecta at least one ethnicity"
  ),
  "client-nhi": Yup.string().test(
    "valid NHI",
    "This doesn't appear to be a valid NHI",
    (v) => v === "" || isNHI(v)
  ),
  "client-gender": Yup.string().required(
    "Please select the option that best describes you"
  ),
  "client-language": Yup.string().required("Please select a language"),
  "client-comm-method": Yup.string().required(
    "Please choose a preferred communication method"
  ),
  "client-living-situation": Yup.string().required(
    "Please select a living situation"
  ),

  //Disability details
  "primary-diagnosis": Yup.string().required(
    "Please indicate a primary diagnosis"
  ),
  "referral-reason": Yup.string().required(
    "Please indicate a reason for referral"
  ),

  //Health Professional
  "acc-claim": Yup.string().required(
    "Please indicate if there is an ACC claim"
  ),

  //Alt contact
  "is-alt-contact-also-emergency": Yup.string().required(
    "Please indicate if the contact above is also the emergency contact"
  ),
};
