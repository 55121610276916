import { appGetState, appNextState, appUpdateState } from "store";
import {
  checkAltLayout,
  getBlankSurvey,
  getPageIndex,
  mergeCachedAndRemoteFormData,
} from "utils/surveyUtils";
import { flushCache, setCachedSurvey, setCachedFormID } from "api/localStorage";
import { IFormData, ISurveyVersion } from "models/Survey";
import {
  getSurveyData,
  putSurveyComplete,
  putSurveyData,
} from "api/privateApi";
import { CURRENT_SURVEY_VERSION, surveyVersions } from "config/SurveyConfig";
import { layoutService } from "services/LayoutService";

const comment = (m: string) => `SurveyService::${m}`;

class SurveyService {
  private appGetState = appGetState;
  private appNextState = appNextState;
  private appUpdateState = appUpdateState;

  private async saveSurveyProgress() {
    const state = this.appGetState();
    const { id, form_data } = state.survey;
    if (id) {
      this.setSurveyToCache(form_data);
      try {
        await putSurveyData(id, form_data);
        layoutService.addToast(
          "Progress saved",
          {
            theme: "success",
          }
        );
      } catch (e) {
        layoutService.addToast(
          "Connection to server lost. \nOnly local copy of referral saved.",
          { theme: "warning", delay: 6000 }
        );
      }
    }
  }
  private setSurveyToCache = (form_data: IFormData) => {
    setCachedSurvey(form_data);
  };

  public async fetchSurvey(id: string) {
    this.appUpdateState(
      (s) => (s.survey.fetching = true),
      comment("fetchingSurvey")
    );
    const remote = await getSurveyData(id);
    const remoteFormdata = {
      questions: remote?.referral_data || {},
      progress: remote?.progress || [],
    } as IFormData;
    const remoteUpdated = remote?.updated || 0;
    const referral_version = remote?.referral_version || CURRENT_SURVEY_VERSION;

    const form_data = await mergeCachedAndRemoteFormData({
      id,
      remoteFormdata,
      remoteUpdated,
      version: surveyVersions[referral_version],
    });
    this.appUpdateState(
      (s) =>
        (s.survey = {
          fetched: true,
          fetching: false,
          altWording: checkAltLayout(form_data.questions.referrer),
          client_first_name: remote?.client_first_name || "",
          client_surname: remote?.client_surname || "",
          referral_version: referral_version,
          id,
          form_data,
        }),
      comment("surveyFetched")
    );
    return form_data;
  }

  public setFormId(id: string) {
    this.appUpdateState((s) => (s.survey.id = id), comment("setFormId"));
    setCachedFormID(id);
  }

  public updateQuestions(newQuestions: Record<string, any>) {
    const state = this.appGetState();
    this.appUpdateState(
      (s) =>
        (s.survey.form_data.questions = {
          ...state.survey.form_data.questions,
          ...newQuestions,
        }),
      comment("updateQuestions")
    );
  }

  public setSurveyPageProgress(index: number, status: boolean) {
    this.appUpdateState(
      (s) => (s.survey.form_data.progress[index] = status),
      comment("setSurveyPageComplete")
    );
  }

  public saveSurveyPage({
    route,
    questions,
    version,
  }: {
    route: string;
    questions: Record<string, any>;
    version: ISurveyVersion;
  }) {
    this.setSurveyPageProgress(getPageIndex(route, version), true);
    this.updateQuestions(questions);
    this.saveSurveyProgress();
  }

  public goBackSurveyPage = ({
    route,
    questions,
    prev,
    version,
  }: {
    route: string;
    questions: Record<string, any>;
    prev: string | undefined;
    version: ISurveyVersion;
  }) => {
    this.setSurveyPageProgress(getPageIndex(route, version), false);
    prev && this.setSurveyPageProgress(getPageIndex(prev, version), false);
    this.updateQuestions(questions);
    this.saveSurveyProgress();
  };

  public resetSurvey() {
    flushCache();
    this.appUpdateState(
      (s) =>
        (s.survey = {
          fetched: false,
          fetching: false,
          altWording: false,
          id: undefined,
          client_first_name: "",
          client_surname: "",
          referral_version: CURRENT_SURVEY_VERSION,
          form_data: getBlankSurvey(surveyVersions[CURRENT_SURVEY_VERSION]),
        }),
      comment("surveyReset")
    );
  }

  public async completeSurvey(id: string) {
    try {
      await putSurveyComplete(id);
    } catch (e) {
      layoutService.addToast("Error completing survey", { theme: "danger" });
    }
  }

  public setSurveyAltWording(alt: boolean) {
    this.appUpdateState(
      (s) => (s.survey.altWording = alt),
      comment("setSurveyAltWording")
    );
  }
}

const surveyService = new SurveyService();
export { surveyService };
