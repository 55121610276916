import * as React from "react";
import logo from "./taikuratrust-logo-white.png";

interface Props {
  className?: string;
}

const AltLogo = React.memo(({ className }: Props) => (
  <img
    src={logo}
    className={className}
    title={"Taikura Trust"}
    alt={"Taikura Trust footer logo"}
  />
));

export default AltLogo;
