import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { store, history } from "store";
import Meta from "components/shared/Meta";
import Routes from "components/Routes";
import { ErrorBoundary } from "components/shared/ErrorBoundary";
import { ToastProvider } from "components/shared/ui/toast/Toast";

const App: React.FC = () => (
  <Provider store={store}>
    <Meta />
    <ConnectedRouter history={history}>
      <ErrorBoundary>
        <ToastProvider>
          <Routes />
        </ToastProvider>
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>
);

export default App;
