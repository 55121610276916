import React from "react";
import classNames from "classnames";
import AltLogo from "components/shared/images/AltLogo";
import styles from "./TaikuraFooter.module.scss";

const links = [
  { label: "Freephone", url: "tel:0800824572", text: "0800 824 5872" },
  { label: "Phone", url: "tel:092786314", text: "09 278 6314" },
  {
    label: "Email",
    url: "mailto:info@taikura.org.nz",
    text: "info@taikura.org.nz",
  },
];

const TaikuraFooter = () => (
  <footer className={classNames(styles.root)}>
    <div className={styles.top}>
      <AltLogo className={styles.logo} />
      <div className={styles.links}>
        {links.map((l) => (
          <div className={styles.link} key={l.text}>
            <div className={styles.label}>{l.label}</div>
            <a href={l.url}>{l.text}</a>
          </div>
        ))}
      </div>
    </div>
    <div className={styles.bottom}>
      Level 1, Building 1 <br />
      570 Mt Wellington Highway <br />
      Mt Wellington,
      <br />
      Auckland 1062
    </div>
  </footer>
);

export default TaikuraFooter;
