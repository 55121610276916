import React from "react";
import classNames from "classnames";
import styles from "components/shared/ui/text-input/TextField.module.scss";
import InputFeedback, {
  TError,
} from "components/shared/ui/form-elements/InputFeedback";

export interface ITextInput {
  name: string;

  label: string;
  description?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  type?: "email" | "text" | "password" | "search" | "tel" | "url" | "date";
  error?: TError;
  placeholder?: string;
  alternative?: string;
  value?: string;
  defaultValue?: string;
  [key: string]: any;
}

const TextInput = React.forwardRef(
  (
    {
      name,
      label,
      description,
      disabled,
      required,
      type = "text",
      className,
      error,
      placeholder,
      alternative,
      value,
      defaultValue,
      ...rest
    }: ITextInput,
    ref: React.Ref<any>
  ) => {
    const defaultV: string | undefined =
      type === "date" && !!defaultValue
        ? new Date(defaultValue).toISOString().split("T")[0]
        : defaultValue;
    const props = defaultValue?{
      defaultValue:defaultV
    }:{}

    return (
      <div className={classNames(className, error && styles.error)}>
        <label
          htmlFor={`text-input-${name}`}
          style={{ display: "block" }}
          className={styles.label}
          id={`text-input-label-${name}`}
        >
          {label}
          {label && description && <br />}
          {description && <span>{description}</span>}
        </label>

        <input
          id={`text-input-${name}`}
          type={type}
          name={name}
          value={value}
          disabled={disabled}
          className={styles.input}
          ref={ref}
          required={required}
          placeholder={placeholder}
          aria-labelledby={`text-input-label-${name}`}
          aria-describedby={error && `error-${name}-${error?.type}`}
          aria-invalid={!!error}
          tabIndex={disabled ? -1 : undefined}
          {...props}
          {...rest}
        />

        <InputFeedback error={error} visible={!!error} name={name} />
      </div>
    );
  }
);

export default TextInput;
