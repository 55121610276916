import React from "react";
import { Hub } from "aws-amplify";
import { SignIn } from "aws-amplify-react";
import { ISignInProps } from "aws-amplify-react/lib/Auth/SignIn";
import Meta from "components/shared/Meta";
import SurveyLayout from "components/shared/layouts/SurveyLayout";
import SignInForm from "components/shared/auth/form/SignInForm";
import styles from "components/views/ReferralForm/ReferralForm.module.scss";
import { SUPPORT_EMAIL } from "constants/site";

export default {};

interface IProps extends ISignInProps {
  match: { params: { userId: string } };
}

export class CustomSignIn extends SignIn {
  signInError = null;
  boundHubAuthListener: (result: any) => void = () => {};

  constructor(props: IProps) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }

  hubAuthListener(result: any) {
    if (!result || !result.payload) {
      return;
    }
    switch (result.payload.event) {
      case "signIn_failure":
        this.signInError = result.payload.data && result.payload.data.message;
        this.setState({ ...this.state });
        return;
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.boundHubAuthListener = this.hubAuthListener.bind(this);
    Hub.listen("auth", this.boundHubAuthListener);
  }

  componentWillUnmount() {
    Hub.remove("auth", this.boundHubAuthListener);
  }

  async handleFormSubmit() {
    return await this.signIn(new Event("Sign In"));
  }
  handleChange(e: any) {
    this.handleInputChange(e);
  }

  showComponent(theme: any) {
    const {
      // @ts-ignore
      match: {
        params: { userId },
      },
    } = this.props;
    this.inputs.username = userId;
    return (
      <SurveyLayout>
        <Meta title={"Referral Form Access"} />
        <div className={styles.root}>
          <h1>Referral Form Access</h1>
          <p>
            Welcome! To access your referral form, please sign in using the
            Access Token you recieved in your email.
            <br />
            If you have any issues please contact{" "}
            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
          </p>
          <SignInForm
            onInputChange={this.handleChange.bind(this)}
            onSubmit={this.handleFormSubmit.bind(this)}
            values={this.inputs}
            errorMessage={this.signInError}
          />
        </div>
      </SurveyLayout>
    );
  }
}
