import React from "react";
import classnames from "classnames";
import styles from "./Button.module.scss";
import LinkWrapper, {
  TLinkWrapper,
} from "components/shared/ui/link-wrapper/LinkWrapper";

export interface TButton extends TLinkWrapper {
  /** Alternative color variations */
  theme?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger";

  /** CSS class to be applied to the button */
  className?: string;

  /** Should the button be disabled */
  disabled?: boolean;

  /** Button type */
  type?: "button" | "submit" | "reset";

  /** Should the corners of the button should be rounded */
  rounded?: boolean;

  /** Should the button be filled in (default) or outlined (ghost) or empty */
  variant?: "filled" | "outlined" | "empty";

  /** Alternative HEX color value for background of the ghost (non-filled) button  */
  altBg?: string;
  ariaLabel?: string;
  children?: React.ReactNode;
}

const Button = (props: TButton) => {
  return (
    <LinkWrapper to={props.to} href={props.href} target={props.target}>
      <ButtonBase {...props} />
    </LinkWrapper>
  );
};

const ButtonBase = ({
  className,
  onClick,
  disabled = false,
  type = "button",
  theme = "default",
  variant = "filled",
  rounded,
  altBg,
  ariaLabel,
  children,
}: TButton) => (
  <button
    className={classnames(
      styles.button,
      theme && styles[theme],
      variant && styles[variant],
      rounded && styles.rounded,
      className
    )}
    disabled={disabled}
    onClick={onClick ? (e) => onClick(e) : () => {}}
    type={type}
    aria-label={ariaLabel}
    style={{ background: altBg }}
  >
    {children}
  </button>
);

export default Button;
