import {
  IFormData,
  IQuestion,
  ISurveyInitialState,
  ISurveyValidations,
  ISurveyVersion,
  SurveyPageConfig,
} from "models/Survey";
import {
  getCachedFormID,
  getCachedSurvey,
  setCachedFormID,
} from "api/localStorage";
import { getVersionWithSchema } from "utils/validationUtils";

export const surveyNav = (route: string, version: ISurveyVersion) => {
  const index = getPageIndex(route, version);
  const nextPage = version.pages[index + 1];
  const prevPage = version.pages[index - 1];
  return {
    index,
    next: nextPage ? nextPage.id : undefined,
    prev: prevPage ? prevPage.id : undefined,
  };
};

export const getPageIndex = (route: string, version: ISurveyVersion) => {
  return version.pages.findIndex((p) => p.id === route);
};

export const getBlankSurvey = (version: ISurveyVersion) => {
  return {
    progress: new Array(version.pages.length) as (boolean | undefined)[],
    questions: version.initialState,
  };
};

interface IMergeProps {
  id: string;
  remoteFormdata: IFormData;
  remoteUpdated: number;
  version: ISurveyVersion;
}

export const mergeCachedAndRemoteFormData = async ({
  id,
  remoteFormdata,
  remoteUpdated,
  version,
}: IMergeProps) => {
  const localId = await getCachedFormID();
  const {
    form_data: localFormData,
    updated: localUpdated,
  } = await getCachedSurvey(version);
  const cleanQ = cleanFormQuestions(remoteFormdata.questions);
  if (localId === id) {
    if (checkTimeDiff(+localUpdated, +remoteUpdated)) {
      return {
        progress: remoteFormdata.progress,
        questions: { ...localFormData.questions, ...cleanQ },
      };
    } else {
      return {
        progress: localFormData.progress,
        questions: { ...cleanQ, ...localFormData.questions },
      };
    }
  } else {
    setCachedFormID(id);
    const blank = getBlankSurvey(version);
    const progress =
      remoteFormdata.progress &&
      blank.progress.length > remoteFormdata.progress.length
        ? [
            ...remoteFormdata.progress,
            ...new Array(
              blank.progress.length - remoteFormdata.progress.length
            ),
          ]
        : remoteFormdata.progress;

    return {
      progress,
      questions: { ...blank.questions, ...cleanQ },
    };
  }
};

//removes null values from remote questions
const cleanFormQuestions = (questions: ISurveyInitialState) => {
  const q = { ...questions };
  Object.keys(q).forEach((key) => q[key] == null && delete q[key]);
  return q;
};

//If local data was updated than 20sec after remote, then prefer remote (accounting for a bit of clockdrift)
const checkTimeDiff = (local: number, remote: number) => {
  return local - +(remote / 1000000).toFixed(0) < 20000;
};

export const checkAltLayout = (referrerState: string) =>
  !["", "myself"].includes(referrerState);

export const injectClientName = (
  text: string,
  firstname = "",
  lastname = "",
  nameMatch = "%>n<%",
  firstNameMatch = "%>f_n<%",
  lastNameMatch = "%>l_n<%"
) => {
  return (
    text &&
    text
      .replace(nameMatch, `${firstname} ${lastname}`)
      .replace(firstNameMatch, firstname)
      .replace(lastNameMatch, lastname)
  );
};

export const formatQuestion = (
  question: IQuestion,
  firstname: string | undefined,
  lastname: string | undefined,
  alt: boolean
) => {
  const {
    label: originalLabel,
    altLabel,
    description: originalDescription,
    altDescription,
    options: originalOptions,
  } = question;

  const label = injectClientName(
    (alt && altLabel) || originalLabel,
    firstname,
    lastname
  );
  const description =
    originalDescription &&
    injectClientName(
      (alt && altDescription) || originalDescription,
      firstname,
      lastname
    );
  const options = originalOptions
    ? originalOptions.map((o) => {
        return { ...o, label: injectClientName(o.label, firstname, lastname) };
      })
    : [];

  return {
    ...question,
    label,
    description,
    options,
  };
};

export const getSurveyPagesById = (versionLayout: SurveyPageConfig[]) =>
  versionLayout.reduce((s, p) => {
    return { ...s, [p.id]: p };
  }, {} as Record<string, SurveyPageConfig>);

const getDefaultVersionState = (versionLayout: SurveyPageConfig[]) =>
  versionLayout.reduce((a, p) => {
    return {
      ...a,
      ...p.sections.reduce((b, s) => {
        return {
          ...b,
          ...s.questions.reduce((c, q) => {
            return { ...c, [q.name]: q.defaultValue || "" };
          }, {}),
        };
      }, {}),
    };
  }, {});

export const formatVersion = (
  versionLayout: SurveyPageConfig[],
  versionValidations: ISurveyValidations
): ISurveyVersion => {
  return {
    pages: versionLayout,
    pagesById: getVersionWithSchema(versionLayout, versionValidations),
    initialState: getDefaultVersionState(versionLayout),
  };
};
