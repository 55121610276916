import React from "react";
import { VerifyContact } from "aws-amplify-react";
import { IAuthPieceProps } from "aws-amplify-react/lib/Auth/AuthPiece";

export default {};

export class CustomVerifyContact extends VerifyContact {
  constructor(props: IAuthPieceProps) {
    super(props);
    this._validAuthStates = ["verifyContact"];
  }

  showComponent(theme: any): JSX.Element {
    this.changeState("signedIn", this.props.authData);
    return <></>;
  }
}
