import React from "react";
import { Route, Switch } from "react-router-dom";
import ROUTES from "constants/routes";
import NotFound from "components/views/viewNotFound";
import { AnimatePresence } from "framer-motion";
import ViewReferralForm from "components/views/viewReferralForm";
import ViewSubmit from "components/views/viewSubmit";
import ViewLanding from "components/views/viewLanding";
import PrivateRoute from "components/shared/auth/PrivateRoute";
import ViewErrorLoadingSurvey from "components/views/viewErrorLoadingSurvey";

const Routes = () => {
  return (
    <Route
      render={({ location }) => (
        <Switch>
          <Route exact path={Object.values(ROUTES)} key={"app"}>
            <AnimatePresence exitBeforeEnter>
              <Switch location={location} key={location.pathname}>
                <Route path={ROUTES.HOME} exact component={ViewLanding} />
                <Route
                  exact
                  path={ROUTES.REFERRAL_SUCCESS}
                  component={ViewSubmit}
                />
                <Route
                  exact
                  path={ROUTES.ERROR}
                  component={ViewErrorLoadingSurvey}
                  key={"error"}
                />
                <PrivateRoute
                  path={[ROUTES.SURVEY_ROOT, ROUTES.SURVEY, ROUTES.UPLOAD]}
                  exact
                  component={ViewReferralForm}
                />
              </Switch>
            </AnimatePresence>
          </Route>
          <Route component={NotFound} key={"notfound"} />
        </Switch>
      )}
    />
  );
};
export default Routes;
