import React from "react";
import cx from "classnames";
import IconError from "components/shared/ui/icons/IconError";
import { motion } from "framer-motion";
import styles from "./InputFeedback.module.scss";

export type TError = {
  message: string;
  type: string;
};

const InputFeedback = ({
  error,
  visible,
  name,
  classNames,
}: {
  error?: TError;
  visible?: boolean;
  name: string;
  classNames?: string;
}) => (
  <motion.div
    initial="hidden"
    variants={variants}
    animate={visible ? "visible" : "hidden"}
    layout
    transition={{
      height: { type: "tween", duration: 0.3 },
      opacity: { duration: 0.15 },
    }}
    key={`error-${name}-${error?.type}`}
    className={cx(styles.inputFeedback, visible && styles.visible, classNames)}
    id={`error-${name}-${error?.type}`}
  >
    <IconError className={styles.icon} /> {error?.message}
  </motion.div>
);

const variants = {
  visible: { opacity: 1, height: "auto" },
  hidden: { opacity: 0, height: 0 },
};

export default InputFeedback;
