import React from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router";
import ViewNotFound from "components/views/viewNotFound";
import AuthWrapper from "components/shared/auth/AuthWrapper";

interface Props extends RouteProps {}

const PrivateRoute = ({ component: Component, ...rest }: Props) => (
  <Route
    render={(props: RouteComponentProps<any>) => {
      if(Component){
        return (
          <AuthWrapper {...props}>
            <Component {...props} />
          </AuthWrapper>
        );
      }
      return <ViewNotFound />
    }}
    {...rest}
  />
);

export default PrivateRoute
