import * as Sentry from "@sentry/react";
import { SENTRY_DSN, SENTRY_RELSEASE } from "constants/site";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import { Store } from "redux";
import { IState } from "store";

const logErrorToSentry = process.env.NODE_ENV !== "development";
const history = createBrowserHistory();

export const errorInit = () => {
  let envSpecificOptions =
    process.env.NODE_ENV === "production"
      ? {}
      : {
        beforeBreadcrumb(breadcrumb: any) {
          //Ignore console logs in development to prevent the payload from getting too large
          return breadcrumb.category === "console" ? null : breadcrumb;
        },
      };

  if (logErrorToSentry) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: process.env.NODE_ENV,
      release: SENTRY_RELSEASE,
      normalizeDepth: 8,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      tracesSampleRate: 1.0,
      ...envSpecificOptions,
    });
  }
};

export const captureError = (e:any)=>{
  if (logErrorToSentry) {
    Sentry.captureException(e)
  }
}

export const logFeedback = (eventId: string) => {
  if (logErrorToSentry) {
    Sentry.showReportDialog({ eventId });
  }
};
export const errorMiddleware = (store: Store<IState>) => {
  Sentry.addGlobalEventProcessor((event) => {
    const state = store.getState();
    const user = state.app.currentUser || {};
    const logState = {
      layout: state.app.layout,
    };

    return {
      ...event,
      extra: {
        ...event.extra,
        "redux:state": logState,
      },
      user: {
        ...event.user,
        ...user,
      },
    };
  });

  return (next: any) => (action: any) => {
    Sentry.addBreadcrumb({
      category: "redux-action",
      message: action.type,
    });

    return next(action);
  };
};


const cleanSurvey = (survey:any)=>{
  return {
    ...survey,
    client_first_name: "" as string | undefined,
    client_surname: "" as string | undefined,
    form_data: {
      progress:survey.form_data.progress,
    },
  }
}

const stateTransformer = (state: any) => {
  const { app, ...rest } = state;
  // Transform the state to remove sensitive information before logging to Sentry
  const { currentUser, auth, survey, ...appState } = app;

  return {
    ...rest,
    app: {
      ...appState,
      survey:cleanSurvey(survey)
    },
  };
};

export const errorStoreEnhancer = Sentry.createReduxEnhancer({
  stateTransformer,
  actionTransformer: (action) => {
    //remove state from redux.action breadcrumb
    const { state, ...rest } = action;
    return { ...rest };
  },
});
