//Checks if an old version of the survey is stored locally
import jwt from "jwt-decode";
import { IFormData, ISurveyVersion } from "models/Survey";
import { getBlankSurvey } from "utils/surveyUtils";
import { CURRENT_SURVEY_VERSION, surveyVersions } from "config/SurveyConfig";

export const flushCache = () => {
  localStorage.clear();
};

const validateCache = () => {
  const localVersion = localStorage.getItem("version");
  const buildVersion = process.env.REACT_APP_VERSION;
  if (localVersion !== buildVersion) {
    flushCache();
    localStorage.setItem("version", buildVersion || "");
  }
  return localVersion !== buildVersion;
};

//Checks if an old version of the survey is stored locally
export const getCachedSurvey = (version?: ISurveyVersion) => {
  validateCache();
  const cachedData = localStorage.getItem("survey::formData");
  const cachedUpdatedTimestamp = localStorage.getItem(
    "survey::updatedTimestamp"
  );
  if (cachedData) {
    return {
      form_data: JSON.parse(cachedData),
      updated: cachedUpdatedTimestamp || 0,
    };
  }
  return {
    form_data: getBlankSurvey(version||surveyVersions[CURRENT_SURVEY_VERSION]),
    updated: 0,
  };
};

export const setCachedSurvey = (surveyState: IFormData) => {
  localStorage.setItem("survey::formData", JSON.stringify(surveyState));
  localStorage.setItem("survey::updatedTimestamp", `${new Date().getTime()}`);
};

export const calculateClockDrift = (
  iatAccessToken: number,
  iatIdToken: number
) => {
  const n = new Date();
  const now = Math.floor(+n / 1000);
  const iat = Math.min(iatAccessToken, iatIdToken);
  return now - iat;
};

export const setCognitoSession = async (token: any) => {
  const { AccessToken, IdToken, RefreshToken } = token.AuthenticationResult;
  const idTokenData = jwt(IdToken) as Record<string, any>;
  const accessTokenData = jwt(AccessToken) as Record<string, any>;
  const username = idTokenData["cognito:username"];
  const clientID = process.env.REACT_APP_AWS_COGNITO_APP_CLIENTID;

  localStorage.setItem(
    `CognitoIdentityServiceProvider.${clientID}.LastAuthUser`,
    username
  );
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${clientID}.${username}.idToken`,
    IdToken
  );
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${clientID}.${username}.accessToken`,
    AccessToken
  );
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${clientID}.${username}.refreshToken`,
    RefreshToken
  );
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${clientID}.${username}.clockDrift`,
    `${calculateClockDrift(accessTokenData["iat"], idTokenData["iat"])}`
  );

  return;
};

export const setCachedFormID = (id: string) => {
  localStorage.setItem("survey::formId", id);
};

export const getCachedFormID = () => {
  const cachedData = localStorage.getItem("survey::formId");
  if (cachedData) {
    return cachedData;
  }
  return undefined;
};
