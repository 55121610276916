import React from "react";
import {
  VerifyContact,
  Authenticator,
  Greetings,
  SignIn,
} from "aws-amplify-react";
import { CustomSignIn } from "./SignIn";
import { CustomVerifyContact } from "./VerifyContact";
import TaikuraAmplifyTheme from "config/amplify-theme";

interface RootProps {
  children: React.ReactNode;
  authState?: string;
}
const SignedInRoutes = ({ authState, children }: RootProps) => {
  return authState === "signedIn" ? <>{children}</> : null;
};

interface Props {
  match: { params: { formId: string; userId: string } };
  location?: any;
  children: React.ReactNode;
}

const AuthWrapper = (props: Props) => {
  return (
    <Authenticator
      hide={[SignIn, Greetings, VerifyContact]}
      theme={TaikuraAmplifyTheme}
    >
      <CustomSignIn {...props} />
      <CustomVerifyContact />
      <SignedInRoutes>{props.children}</SignedInRoutes>
    </Authenticator>
  );
};

export default AuthWrapper;
