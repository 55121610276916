import React, { useEffect } from "react";
import Meta from "components/shared/Meta";
import SurveyLayout from "components/shared/layouts/SurveyLayout";
import { surveyService } from "services/SurveyService";
import { authService } from "services/AuthService";
import Button from "components/shared/ui/button/Button";
import ROUTES from "constants/routes";

const ViewSubmit = () => {
  const reset = async () => {
    await authService.logout();
    surveyService.resetSurvey();
  };
  useEffect(() => {
    reset();
  }, []);

  return (
    <SurveyLayout>
      <Meta title={"Referral Submitted"} />
      <h1>Referral Submitted</h1>
      <p>
        Talofa, Kia Ora, Ni Hao, Hello.
        <br />
        <br />
        <b>Thank you for submitting your referral to Taikura Trust.</b>
        <br />
        <br />
        Your referral has now been received and will be reviewed by our Pathways
        Team. To have a referral accepted by Taikura Trust, our Pathways team
        need to establish eligibility.
        <br />
        Your referral will be processed within 14 working days.
        <br />
        <br />
        A Needs Assessment will be booked directly with the person this referral
        has been made for (unless specified). A booking for a Needs Assessment
        will be made by one of our team members. At present a Needs Assessment
        booking will be approximately 4-6 weeks from the day you have made the
        referral.
        <br />
        <br />
        We look forward to meeting with you soon
        <br />
        <br />
      </p>
      <Button type="submit" theme="default" to={ROUTES.HOME}>
        Create new Referral
      </Button>
    </SurveyLayout>
  );
};

export default ViewSubmit;
