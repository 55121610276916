import React, { createContext, useContext, useEffect, useState } from "react";
import cx from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import ToastPortal from "components/shared/ui/toast/ToastPortal";
import IconCancel from "components/shared/ui/icons/IconCancel";
import { useOnMount } from "hooks/useOnMount";
import styles from "./Toast.module.scss";
import { useSelector } from "react-redux";
import { IState } from "store";
import { layoutService } from "services/LayoutService";

export interface IToastOptions {
  id?: string;
  delay?: number;
  autoDismiss?: boolean;
  theme?: ThemeVariants;
}
export interface IToastStateElement extends Omit<IToastOptions, "id"> {
  id: string;
  content: string | React.ReactNode;
}

interface Props extends Omit<IToastOptions, "id"> {
  id: string;
  children: string | React.ReactNode;
}

export interface IToast {
  content: string | React.ReactNode;
  options?: IToastOptions;
}

const Toast = React.memo(
  ({ children, id, delay = 3000, autoDismiss = true, theme }: Props) => {
    const [animation, setAnimation] = useState({
      variant: "start",
      duration: 0,
    });
    useOnMount(() => {
      if (!autoDismiss) {
        return;
      }
      const timer = setTimeout(() => handleClose(), delay);
      setAnimation({
        variant: "progress",
        duration: delay / 1000,
      });
      return () => clearTimeout(timer);
    });

    const handleClose = () => {
      layoutService.removeToast(id);
    };
    const handleDragEnd = async (event: any, info: any) => {
      if (Math.abs(info.offset.x) > 150 || Math.abs(info.velocity.x) > 500) {
        handleClose();
      }
    };

    return (
      <motion.li
        className={cx(styles.toast, theme && styles[theme])}
        layout
        initial={{ opacity: 0, y: 50, scale: 0.3 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
        whileTap={{ cursor: "grabbing" }}
        drag="x"
        onDragEnd={handleDragEnd}
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={0.6}
      >
        <div className={styles.toastContent}>{children}</div>

        <button onClick={() => handleClose()} className={styles.close}>
          <IconCancel />
        </button>
        <div className={styles.progressWrapper}>
          <motion.div
            className={styles.progress}
            initial={"start"}
            animate={animation.variant}
            variants={{
              start: { x: "-100%" },
              progress: { x: 0 },
              pause: { x: "inherit" },
            }}
            transition={{ ease: "linear", duration: animation.duration }}
          />
        </div>
      </motion.li>
    );
  }
);

interface IContext {
  toasts: IToastStateElement[];
  add: (toast: IToast) => void;
  remove: (id: string) => void;
}
const ToastContext = createContext<IContext>({
  toasts: [],
  add: () => undefined,
  remove: () => undefined,
});

export const useToast = () => {
  const ctx = useContext(ToastContext);
  if (!ctx) {
    throw Error(
      "The `useToasts` hook must be called from a descendent of the `ToastProvider`."
    );
  }

  return {
    addToast: ctx.add,
    removeToast: ctx.remove,
    toastStack: ctx.toasts,
  };
};

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const toasts = useSelector((s: IState) => s.app.layout.toasts);
  const [portal, setPortal] = useState(false);

  const add = ({ content, options }: IToast) => {
    layoutService.addToast(content, options);
  };

  const remove = (id: string) => {
    layoutService.removeToast(id);
  };

  useEffect(() => {
    if (toasts.length > 0 && !portal) {
      setPortal(true);
    } else if (toasts.length === 0 && portal) {
      setTimeout(() => {
        setPortal(false);
      }, 200);
    }
  }, [portal, toasts.length]);

  return (
    <ToastContext.Provider value={{ toasts, add, remove }}>
      {children}
      {portal && (
        <ToastPortal>
          <AnimatePresence initial={true}>
            {toasts.map((t) => (
              <Toast key={t.id} {...t}>
                {t.content}
              </Toast>
            ))}
          </AnimatePresence>
        </ToastPortal>
      )}
    </ToastContext.Provider>
  );
};
