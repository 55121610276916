
const API = process.env.REACT_APP_API_URL
const PUBLIC_API = process.env.REACT_APP_PUBLIC_API_URL

export const CREATE_REFERRAL = `${PUBLIC_API}/referrals`
export const PRIVATE_FORMS = `${API}/referrals`

const ADDRESS_API = `https://api-nz.addysolutions.com`
export const ADDRESS_AUTOCOMPLETE = `${ADDRESS_API}/search`
export const ADDRESS_DETAILS = `${ADDRESS_API}/address`
export const ADDRESS_API_KEY = process.env.REACT_APP_ADDRESS_API_KEY

