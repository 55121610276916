import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { IQuestion, ISurveyValidations, SurveyPageConfig } from "models/Survey";

export const getSchema = (questions: IQuestion[]) => {
  return yupResolver(
    Yup.object().shape(
      questions
        .filter((q) => !!q.validation)
        .reduce((s, q) => {
          return { ...s, [q.name]: q.validation };
        }, {})
    )
  );
};

export const getVersionWithSchema = (
  version: SurveyPageConfig[],
  versionValidations: ISurveyValidations
) => {
  return version.reduce((v, page) => {
    const schema = {} as Record<string, any>;
    page.sections?.forEach((s) => {
      s.questions.forEach((q) => {
        if (!!versionValidations[q.name]) {
          schema[q.name] = versionValidations[q.name];
        }
      });
    });
    return {
      ...v,
      [page.id]: { ...page, schema: yupResolver(Yup.object().shape(schema)) },
    };
  }, {});
};

// Source: https://gist.github.com/jnimmo/e7a0eadb05db07865b0c

// Description: Returns true if a New Zealand NHI passes checksum validation, otherwise returns false.
export const isNHI = (nhi: string | undefined | null) => {
  if (typeof nhi !== "string"){
    return false
  }
  const NHI = nhi.toUpperCase().trim();
  let passTest = false;
  // Validation steps 1 and 2
  if (/^[A-HJ-NP-Z]{3}[0-9]{4}$/.test(NHI)) {
    let checkValue = 0;
    // Init alpha conversion table omitting O and I. A=1~Z=24
    const alphaTable = "ABCDEFGHJKLMNPQRSTUVWXYZ".split("");
    for (let i = 0; i < 3; i++) {
      // Convert each letter to numeric value from table above
      const letterNumeric = alphaTable.indexOf(NHI[i]) + 1;
      // Multiply numeric value by 7-i and add to the checkvalue
      checkValue += letterNumeric * (7 - i);
    }
    for (let i = 3; i < 6; i++) {
      // Multiply first three numbers by 7-i and add to the checkvalue
      checkValue += Number(NHI[i]) * (7 - i);
    }
    // Apply modulus 11 to the sum of the above numbers (checkValue)
    const checkSum = checkValue % 11;
    // If the checksum is not 0, subtract the checksum from 11
    // to create checkdigit. Mod 10 to convert 10 to 0.
    if (checkSum !== 0) {
      if (Number(NHI[6]) === (11 - checkSum) % 10) {
        // if the last digit of the NHI matches the checkdigit, NHI is valid
        passTest = true;
      }
    }
  }
  return passTest;
};

const today = new Date();
const getAge = (dob: Date) => {
  let age = today.getFullYear() - dob.getFullYear();
  const m = today.getMonth() - dob.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
    age--;
  }
  return age;
};

export const checkUnder65 = (d: string | undefined | null) => {
  if (d && d.trim().length === 10) {
    const dob = new Date(d.trim());
    const age = getAge(dob);
    return age < 65;
  }
  return false;
};
