import React from "react";
import { Link } from "react-router-dom";

export interface TLinkWrapper {
  /** React DOM Link target */
  to?: string;
  /** Normal href attribute */
  href?: string;
  /** Href target attribute (e.g. '_blank') */
  target?: string;
  /** Callback function triggered on click */
  onClick?: (e: any) => void;
  children?: any;
}

export const LinkWrapper = (props: TLinkWrapper) => {
  if (props.to) {
    return <Link to={props.to || ""}>{props.children}</Link>;
  }
  if (props.href) {
    return (
      <a href={props.href || ""} target={props.target || "_blank"}>
        {props.children}
      </a>
    );
  }
  return props.children;
};

export default LinkWrapper;
