import React from "react";
import Meta from "components/shared/Meta";
import SurveyLayout from "components/shared/layouts/SurveyLayout";
import HeroButton from "components/shared/ui/hero-button/HeroButton";
import { SUPPORT_EMAIL } from "constants/site";
import * as ROUTES from "constants/routes";

const ViewNotFound = () => (
  <SurveyLayout>
    <Meta title={"Not Found"} />

    <h1>Page Not Found</h1>
    <p>
      Sorry, it seems you were trying to access a page that doesn't exist.
      <br />
      <br />
      Please check the spelling of the URL you were trying to access and try
      again.
      <br />
      <br />
      If you keep having trouble, please contact{" "}
      <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
    </p>
    <div style={{ paddingTop: 16 }}>
      <HeroButton to={ROUTES.HOME} theme={"secondary"} variant="outlined">
        Back to Home
      </HeroButton>
    </div>
  </SurveyLayout>
);

export default ViewNotFound;
