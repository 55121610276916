import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { getSchema } from "utils/validationUtils";
import { IQuestion } from "models/Survey";
import Button from "components/shared/ui/button/Button";
import styles from "components/views/ReferralForm/ReferralForm.module.scss";
import PasswordTextInput from "components/shared/ui/text-input/PasswordTextField";

const questions = [
  // {
  //   name: "username",
  //   validation: Yup.string().required("Please enter your Username"),
  // },
  {
    name: "password",
    validation: Yup.string().required("Please enter your Access Token"),
  },
] as IQuestion[];

const cleanError = (e: string) => {
  switch (e) {
    case "Incorrect username or password.":
      return "Incorrect Access Token";

    case "User does not exist.":
      return "Incorrect URL - please check the link you recieved in your email";

case "User is disabled.":
      return "Referral complete. No further edits allowed";

    default:
      return e;
  }
};

const schema = getSchema(questions);

interface Props {
  onSubmit: () => void;
  onInputChange: (evt: any) => void;
  values: any;
  errorMessage: string | null;
}

const SignInForm = ({ onSubmit, values, errorMessage }: Props) => {
  const [submitting, setSubmitting] = useState(false);

  let defaultValues = {
    password: values.password || "",
  };
  const { handleSubmit, errors, register } = useForm({
    mode: "onBlur",
    resolver: schema,
    defaultValues: defaultValues,
  });
  const submit = async (data: any) => {
    // values.username = id;
    setSubmitting(true);
    values.password = data.password.trim();
    await onSubmit();
    setSubmitting(false);
  };

  return (
    <form
      onSubmit={handleSubmit(submit)}
      aria-label="referral signin"
      noValidate
    >
      {errorMessage && (
        <div className={styles.error}>Error: {cleanError(errorMessage)}</div>
      )}

      <PasswordTextInput
        name="password"
        label="Access Token"
        type="password"
        ref={register()}
        error={errors["password"]}
      />

      <div className={styles.formActions}>
        <Button type="submit" theme="primary" disabled={submitting}>
          {submitting ? "Signing In..." : "Sign in"}
        </Button>
      </div>
    </form>
  );
};

export default SignInForm;
